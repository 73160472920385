// required modules
import Model from '@/models/Model'

/**
 * This class describes a GCategoryDocument model.
 *
 * @class GCategoryDocument
 */
export default class GCategoryDocumentModel extends Model {

  /**
   * Define the GCategoryDocument resource.
   *
   * @return {String} GCategoryDocument endpoint name.
   */
  static resource() {
    return 'category_document'
  }
}