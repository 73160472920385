// required modules
import Service from '@/services/api/Service'
import GCategoryDocumentModel from '@/models/General/GCategoryDocument'

/**
 * This class describes a GCategoryDocument service.
 *
 * @class GCategoryDocument (name)
 */
export default class GCategoryDocumentService extends Service {

  /**
   * Define the GCategoryDocument model.
   *
   * @return {Function} GCategoryDocument resource.
   */
  static model() {
    return GCategoryDocumentModel
  }

  /**
   * 
   */
  static getAll() {
    return this
      .query()
      .param('get_all_results', true)
      .paginate()
  }
  
  /**
   * Obtiene los servicios de cada proveedor con el filtro "search"
   * @param {*} search 
   * @return {Promise}
   */
  static getAllBySearch(search = '') {
    return this
      .query()
      .where('search', search)
      .param('get_all_results', true)
      .paginate()
  }
}